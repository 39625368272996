@import "./normalize.less";

html {
  body {

    * {
      box-sizing: border-box;
    }

    /* ant-input-search搜索框 */
    .ant-input-search {
      max-width: 250px;
    }

    /* ant-pagination分页 */
    .ant-pagination {
      margin-top: @vab-margin;
      text-align: center;

      &.ant-table-pagination {
        float: none !important;
        margin-top: @vab-margin;
      }

    }


  }
}