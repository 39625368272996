
  .vab-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    margin: 16px 5px;
    overflow: hidden;
    overflow: hidden;
    font-size: 15px;
    color: #fff;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

@vab-color-blue: #1890ff;@vab-margin: 20px;@vab-padding: 20px;@vab-header-height: 65px;